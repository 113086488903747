<template>
  <Layout :tituloPagina="`Network | DHs | Marcas | ${modo == 'nueva' ? 'Nueva' : 'Edicion'}`">
    <div class="row">
      <div class="col-md-4 col-sm-5">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nueva' ? 'Nueva' : 'Edición de' }}
              marca de DHs
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nueva' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label class="col-md-4">Nombre</label>
              <div class="col-md-8">
                <input
                  ref="nombre"
                  v-model="marca.nombre"
                  class="form-control"
                  placeholder="Nombre de la marca"
                  name="nombre"
                />
              </div>
            </div>
            <br>
            <div class="row">
              <label class="col-md-4">Foto</label>
              <div class="col-md-8 text-center">
                <div id="marcoFoto">
                  <div
                    id="divBtnFoto"
                    @click="agregarArchivo()"
                    v-if="srcFoto == ''"
                  >
                    {{ srcFoto == '' ? 'Seleccionar imagen' : '' }}
                  </div>
                  <div v-if="srcFoto != ''">
                    <button
                      type="button"
                      @click="eliminarArchivo()"
                      class="btnEliminarFoto btn btn-danger btn-sm btn-rounded"
                    >
                      <i class="mdi mdi-close-thick"></i>
                    </button>
                    <img id="imagenMarca" :src="srcFoto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="cerrar()">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button
              class="btn btn-success"
              @click="modo == 'nueva' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import MarcaDhsSrv from '@/services/MarcaDhsSrv.js'
import API from '@/API.js'
export default {
  name: 'EdicionMarcaDhs',
  components: { Layout },
  data() {
    return {
      baseUrl: window.location.origin, 
      marca: {
        id: null,
        nombre: '',
        id_foto: null,
        _eliminarFotoAntigua: false
      },
      marcaInicial: {},
      srcFoto: '',
      modo: 'nueva',
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this

    if (this.$route.path.indexOf('nueva') == -1) this.modo = 'edicion'

    // Registro de copia inicial
    this.marcaInicial = Object.assign({}, this.marca)

    //Cargas iniciales
    if (this.modo == 'edicion') self.cargarMarca()
  },

  methods: {
    actualizar: function() {
      var self = this
      var marca = Object.assign({}, self.marca)

      self.bandera_spinner = true

      if(marca.nombre == '') {
        iu.msg.warning('Se necesita el nombre de la marca para el DH')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }
      
      MarcaDhsSrv.actualizar(marca).then(response => {
        iu.msg.success('Se actualizó correctamente')
        self.cargarMarca()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actializar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    agregarArchivo: function() {
      var self = this

      var marcoFoto = document.getElementById("marcoFoto")
      var input = document.createElement("input")
      input.setAttribute('type', 'file')
      input.setAttribute('name', 'foto')
      input.setAttribute('style', 'display: none')
      marcoFoto.append(input)

      input.click()
      input.addEventListener('change', function(e) {
        var input = e.target

        var reader = new FileReader()
        reader.onload = function(e1) {
          self.srcFoto = e1.target.result
        }

        if (input.files.length > 0) {
          reader.readAsDataURL(input.files[0])
        }
      })
    },

    cargarMarca: function() {
      var self = this
      var idMarca = this.$route.params.id

      MarcaDhsSrv.marcaJSON(idMarca).then(response => {
        let marca = response.data
        self.marca = marca

        if (marca.id_foto != null && marca.id_foto != '' && marca.id_foto != 0)
          self.srcFoto =
            API +
            '/dhs/marcas/' +
            marca.id +
            '/imagen?tipo_foto=256x256&_tk=' +
            localStorage.getItem('argusblack.token') +
            '&_tiempo=' +
            new Date().getTime()
        else self.srcFoto = ''
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la marca'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cerrar: function() {
      this.$router.go(-1)
    },

    eliminarArchivo: function() {
      var self = this

      self.marca._eliminarFotoAntigua = true
      self.srcFoto = ''

      var inputs = document.getElementsByName("foto")
      if(inputs.length > 0) inputs[0].remove()
    },

    guardar: function() {
      var self = this
      var marca = Object.assign({}, self.marca)

      self.bandera_spinner = true

      if(marca.nombre == '') {
        iu.msg.warning('Se necesita el nombre de la marca para el DH')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      MarcaDhsSrv.guardar(marca).then(response => {
        iu.msg.success('Se guardó correctamente')
        self.limpiar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    limpiar: function() {
      this.marca = Object.assign(this.marca, this.marcaInicial)
      this.srcFoto = ''
    }
  },
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}

#imagenMarca {
  width: 100%;
}

#marcoFoto {
  border: thin solid #ccc;
  border-radius: 0.75rem;
  background-color: #fafafa;
  width: 100%;
  min-height: 100px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

#divBtnFoto {
  width: 100%;
  height: 100px;
  line-height: 100px;
}

.btnEliminarFoto {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10;
}
</style>